.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.body {
  margin: 0;
  background-color: #f8f9fa;
}
/* body {
  background-image: url('./images/general-bg.jpg');
  background-repeat: repeat;
  background-position: center center;
  background-attachment: fixed;
  z-index: 9999;
} */
/* src/App.css */

/* Blurred effect, linked with countdown */
.blur {
  filter: blur(10px); /* Full blur at countdown 10 */
  transition: filter 1s ease; /* Smooth transition effect */
}
.countdown-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 5rem; /* Adjust size for visibility */
}

.welcome-screen {
  transition: filter 0.5s ease-in-out;
}


.countdown-overlay h1 {
  color: white;
  font-size: 5rem;
  animation: pulse 1s infinite;
}

/* Optional pulse effect for countdown */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}