/* Carousel styles */
.carousel {
  max-height: 400px; /* Default height for larger screens */
}

.carousel-item img {
  max-height: 400px; /* Default max-height for larger screens */
  object-fit: cover; /* Ensures images cover the container without distortion */
}

@media (max-width: 768px) {
  .carousel {
      max-height: 600px; 
      margin-top: 20%;
  }

  .carousel-item img {
      max-height: 600px; /* Increase height for mobile */
  }
}

/* Adjustments for centering */
.my-container {
  padding: 10px 0;
}




/* src/css/homepage.css */

.welcome-screen {
  position: relative;
  text-align: center;
}

.animated-text {
  font-size: 4rem;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
}

.char {
  display: inline-block;
  opacity: 0;
  transform: scale(0.8);
  animation: fadeInUp 0.5s forwards;
  font-family: 'Arial', sans-serif; /* Use a bold and clean font for a Marvel-like effect */
  font-size: 4rem; /* Adjust size to your preference */
  background: linear-gradient(90deg, #f75f28, #ffcc00); /* Example gradient color */
  -webkit-background-clip: text; /* Clip the background to the text */
  -webkit-text-fill-color: transparent; /* Make text fill transparent to show gradient */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Add a shadow effect */
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
