.mb-3 {
    margin-bottom: 1.5rem;
  }
  
  .mb-4 {
    margin-bottom: 2rem;
  }
  
  .card img {
    height: 150px;
    object-fit: cover;
  }
  .card-img-top {
    height: 200px; /* Set a fixed height for images */
    object-fit: cover; /* Ensure the aspect ratio is maintained */
  }
  
  /* Media query for smaller devices */
  @media (max-width: 576px) {
    .card-img-top {
      height: 150px; /* Adjust height for small screens */
    }
  }
  