.header {
    background-color: #f8f9fa; /* Background color for the header */
    padding: 20px;
    text-align: center;
}

.animated-title {
    font-family: 'Fantasy', cursive; /* Customize font family */
    font-size: 48px; /* Adjust size as needed */
    color: #f75f28; /* Customize text color */
    animation: fadeIn 2s ease-in-out infinite alternate;
}

/* Animation keyframes */
@keyframes fadeIn {
    0% {
        opacity: 0.5;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
