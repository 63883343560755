/* Footer.css */

footer {
    position: relative;
    bottom: 0;
    width: 100%;
    height: auto; /* Ensures the footer takes up space */
  }
  
  footer h5 {
    border-bottom: 2px solid #fff; /* Optional: adds a line under the headings */
    padding-bottom: 10px;
  }
  
  footer p {
    margin-bottom: 0.5rem; /* Adjusts spacing between paragraphs */
  }
  
  footer a {
    transition: color 0.3s;
  }
  
  footer a:hover {
    color: #ffc107; /* Change color on hover */
  }
  